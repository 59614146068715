import { gql } from '@apollo/client'
import { ISeoComponent, infoSeoFragment } from 'components/dynamic-page/contentfulComponents/InfoSeo'
import { IEntry, entryFragment } from 'components/dynamic-page/shared/types/Entry.type'
import { pageHelperSharedComponentsFragment } from 'components/dynamic-page/shared/types/PageHelperSharedComponents'

export interface IPageHome extends IEntry {
  seo: ISeoComponent
  title: string
  showSpanishPage: boolean
  header: IEntry
  sectionsCollection: {
    items: IEntry[]
  }
}

export const PageHomeQuery = gql`
  query Page(
    $preview: Boolean = false
    $locale: String = "en-US"
    $pageHelperSlug: String = "generic-layout"
  ) {
    pageHomeCollection(
      limit: 1
      locale: $locale
      preview: $preview
    ) {
      items {
        sys {
          id
        }
        title
        showSpanishPage
        seo {
          ...infoSeoFragment
        }
        header {
          ...entryFragment
        }
        sectionsCollection(limit: 30) {
          items {
            ...entryFragment
          }
        }
      }
    }
    ...pageHelperSharedComponentsFragment
  }
  ${pageHelperSharedComponentsFragment}
  ${entryFragment}
  ${infoSeoFragment}
`
