import { localeSubPathMap } from '@common/utils/language'
import PageHome, { PageHomeQuery } from 'components/dynamic-page/pages/PageHome'
import { withPageContexts } from 'components/shared/components/GlobalProviders'
import { getDynamicPageContent } from 'components/shared/utils'
import { GetStaticProps, GetStaticPropsContext } from 'next'
import React from 'react'

export async function getStaticPaths() {
  if (process.env.DRAFT) {
    return {
      paths: [],
      fallback: 'blocking'
    }
  }

  return {
    paths: ['/en', '/es-mx'],
    fallback: 'blocking'
  }
}

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  const {
    params, preview
  } = context
  const locale = params?.lang as string

  const dynamicPageData = await getDynamicPageContent({
    query: PageHomeQuery,
    variables: {
      preview,
      locale: localeSubPathMap.get(locale),
      slug: '/'
    },
    siteSection: 'homepage',
    locale
  })

  if (!dynamicPageData.pageProps.dynamicPage) {
    return {
      notFound: true
    }
  }

  return {
    props: {
      ...dynamicPageData,
      slugOverride: '/',
      layoutConfig: {
        header: {
          customClass: 'bg-background-low-ochreLite'
        },
        hideCallOutBanner: true
      }
    }
  }
}

function PageContent({ dynamicPage }: { dynamicPage: any }) {
  return (
    <PageHome
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...dynamicPage}
    />
  )
}

export default withPageContexts(PageContent)
