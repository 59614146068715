import DynamicComponentRenderer from 'components/dynamic-page/DynamicComponentRenderer'
import { ContentProvider } from 'components/dynamic-page/providers/ContentContext'
import { PageComponentsProvider } from 'components/dynamic-page/providers/PageComponentsContext'
import Seo from 'components/shared/components/Seo'
import { IPageHome } from './PageHome.type'

function PageHome({
  title,
  seo,
  header,
  sectionsCollection,
  ...props
}: IPageHome) {
  return (
    <div className='p-sm-relative o-sm-hidden'>
      <Seo
        seo={seo}
        fallbackTitle={title}
        ogType='basic'
        slug='/'
        showSpanishPage
      />

      <ContentProvider>
        {/* <PageProvider pageLayout={pageLayout}> */}
        <PageComponentsProvider
          parent={props}
          entries={[header, ...sectionsCollection.items].filter((c) => !!c)}
        >

          <DynamicComponentRenderer block={header} />
          <DynamicComponentRenderer block={sectionsCollection.items} />

        </PageComponentsProvider>
        {/* </PageProvider> */}
      </ContentProvider>
    </div>
  )
}

export default PageHome
